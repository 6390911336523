<template>
  <ModalModule
    v-if="download"
    v-model="activeModal"
    :title="
      $textDictionary['productDocumentDownloadTab.pageTitle'] ??
      'Document Download'
    "
    close-icon-text="close"
    :is-full-screen="true"
    :esc-to-close="true"
    :click-to-close="false"
    @abort="emits('closeModal')"
    @after-close="emits('afterCloseModal')"
  >
    <div v-if="!isDownloaded" class="row">
      <div class="d-lg-flex flex-lg-row-reverse">
        <div
          v-if="shareUrl && shareTitle"
          class="col-lg-3 text-lg-end pb-4 col-sm-12"
        >
          <ShareButton
            :url="shareUrl"
            :title="shareTitle"
            :share-text="
              $textDictionary['downloadCenter.share.label'] ?? 'Share Download'
            "
            :copied-text="
              $textDictionary['downloadCenter.copied.label'] ??
              'Copied to Clipboard'
            "
            :copy-text="
              $textDictionary['downloadCenter.copy.label'] ??
              'Copy download link'
            "
            @click="handleOnShareClick"
          />
        </div>
        <div class="col-lg-9 col-sm-12">
          <h2 :class="$style.downloadName">{{ download.name }}</h2>
          <MetaList class="mb-4" :items="metaListItems" />
          <div class="rte-text mb-4" v-html="download.abstract"></div>
          <template v-if="hasMultipleDownloads">
            <h4>
              {{
                $textDictionary[
                  'productDocumentDownloadTab.chooseLanguage.label'
                ] ?? 'Choose document language'
              }}
            </h4>
            <TabsComponent
              :class="$style.tabsComponent"
              title="title"
              tab-list-position="top"
              :tab-list-is-fullwidth="false"
              :state="tabState"
            >
              <TabPanel
                v-for="linkKey of Object.keys(download.mediaLink)"
                :key="linkKey"
                :uid="linkKey"
                :title="useLanguageLabel(linkKey as ContentfulLocaleCode)"
                :active-on-init="initialActiveTabMap[linkKey] ?? false"
              >
                <LinkElement
                  v-if="!downloadLocked || !formUrl"
                  :link="download.mediaLink[linkKey as ContentfulLocaleCode] ?? '#'"
                  style-type="primary"
                  :target-blank="true"
                  class="mt-2 mb-2"
                  download
                  @click="() => (isDownloaded = true)"
                >
                  {{
                    $textDictionary['downloadCenter.modal.downloadLabel'] ??
                    'Download'
                  }}
                </LinkElement>
              </TabPanel>
            </TabsComponent>
          </template>
          <LinkElement
            v-else-if="(!downloadLocked || !formUrl) && downloadLink"
            :link="downloadLink"
            style-type="primary"
            :target-blank="true"
            class="mt-2 mb-2"
            download
            @click="() => (isDownloaded = true)"
          >
            {{
              $textDictionary['downloadCenter.modal.downloadLabel'] ??
              'Download'
            }}
          </LinkElement>
          <PardotFormIframe
            v-if="downloadLocked && formUrl"
            :form-url="formUrl"
            @success="handleFormSuccess"
          />
        </div>
      </div>
    </div>
    <DownloadsDownloadCenterModalSuccessBlock
      v-else
      :download-link="downloadLink"
      :hande-back-to-overview="handleBackToOverview"
    />
  </ModalModule>
  <ModalModule
    v-else
    v-model="activeModal"
    class="rte-text"
    :title="
      $textDictionary['productDocumentDownloadTab.pageTitle'] ??
      'Document Download'
    "
    close-icon-text="close"
    :esc-to-close="true"
    :click-to-close="false"
    @abort="emits('closeModal')"
    @after-close="emits('afterCloseModal')"
  >
    <LibRichTextRenderer
      :rich-text="$dictionary['downloadCenter.modal.notFound']"
    />
  </ModalModule>
</template>

<script setup lang="ts">
import {
  LinkElement,
  ModalModule,
  TabsComponent,
  TabPanel,
  ShareButton,
  MetaList
} from '@hypercodestudio/basler-components';
import PardotFormIframe from '~/components/downloads/PardotFormIframe.vue';
import type { DownloadEntryModel } from '~/lib/DownloadService/model';
import type { ContentfulLocaleCode } from '~/lib/ContentfulService/model';
import type { MetaItem } from '@hypercodestudio/basler-components/dist/components/elements/MetaList.vue';
import type { TabsStateInterface } from '@hypercodestudio/basler-components/dist/models/tabs';
import type { HyperlinkInterface } from '@hypercodestudio/basler-components/dist/components/helpers/HyperLink.vue';
import { useDocumentLanguage } from '~/lib/DownloadService/helper/useDocumentLanguage';
import { useLanguageLabel } from '~/stores/downloadStore/useFilterValueLabel';

const {
  $resolvedLocale,
  $dictionary,
  $textDictionary,
  $globalPageSettings,
  $currentEnvName,
  $analytics
} = useNuxtApp();
const logger = useLogger();
const locale = useLocale();

interface Props {
  download?: DownloadEntryModel | null;
  open?: boolean;
  preferredLocales?: ContentfulLocaleCode[];
}

// we access the tab state to find out, which tab is currently active
// instead of using a callback on the emit.
const tabState = reactive<TabsStateInterface>({
  tabs: []
});
const activeLanguage = computed<ContentfulLocaleCode | undefined>(
  () =>
    tabState.tabs.find((tab) => tab.isActive)
      ?.computedId as ContentfulLocaleCode
);

const props = defineProps<Props>();

const emits = defineEmits<{
  /**
   * Once the modal was close via the "close" button (is not triggered via "esc").
   */
  closeModal: [];
  /**
   * After the modal was closed (emits always, also while closing via "esc").
   */
  afterCloseModal: [];
}>();

/**
 * Indicated if the download was initiated by the user in the browser.
 */
const isDownloaded = ref(false);

const shareUrl = computed(() =>
  props.download?.id
    ? buildUrlString(
        locale.value,
        `${
          $globalPageSettings.value?.documentCenterPage?.metadata?.slug ?? ''
        }/${props.download.id}`,
        undefined,
        undefined,
        true
      )
    : undefined
);
const shareTitle = computed(() => props.download?.name);

// e.g. 'https://info.baslerweb.com/l/73192/2020-02-24/98pcbf';
const formUrl = computed(() => {
  const urlString = $globalPageSettings.value?.documentsPardotFormUrl;
  if (!urlString) {
    return;
  }

  try {
    const formUrl = new URL(urlString);
    formUrl.searchParams.append('domain', $currentEnvName.value);

    const filename = props.download?.name;
    if (filename) {
      formUrl.searchParams.append('filename', filename);
    }

    return formUrl.toString();
  } catch (e) {
    logger.error('could not create new url from pardot url', e);
  }
});

const activeModal = ref(props.open);
const downloadLocked = ref(props.download?.formRequired ?? false);

const hasMultipleDownloads = computed(
  () => props.download && Object.keys(props.download.mediaLink).length > 1
);

const matchingLocale = computed(() => {
  if (!props.download || !props.preferredLocales) {
    return;
  }

  const existingLocales = Object.keys(props.download.mediaLink);

  return props.preferredLocales.find((preferredLocale) =>
    existingLocales.includes(preferredLocale)
  );
});

/**
 * A download link the current active language if language selection is
 * available. No active language is given (which should only the case if
 * only one language is available), the first download link is returned.
 * If no `download` is passed via props or `props.mediaLink` is empty,
 * `undefined` is returned.
 */
const downloadUrl = computed<string | undefined>(() => {
  if (!props.download) {
    return;
  }

  if (activeLanguage.value) {
    // if `undefined` is observed, there must be an error regarding the uids in
    // the tab modul.
    return props.download.mediaLink[activeLanguage.value];
  }

  const downloadLink = Object.values(props.download.mediaLink)[0];
  if (!downloadLink) {
    return;
  }

  return downloadLink;
});

const downloadLink = computed<HyperlinkInterface | undefined>(() => {
  if (!downloadUrl.value) {
    return;
  }

  return {
    uri: downloadUrl.value,
    target: '_blank',
    external: true
  };
});

type InitActiveTabMap = Partial<Record<string | ContentfulLocaleCode, boolean>>;

/**
 * A map which contains the initial active tab for the preferred locale.
 *
 * @see https://gcp.baslerweb.com/jira/browse/DBP-1272
 */
const initialActiveTabMap = computed<InitActiveTabMap>(() => {
  if (!props.download?.mediaLink) {
    return {};
  }

  const languageActiveMap: InitActiveTabMap = Object.fromEntries(
    Object.keys(props.download.mediaLink).map((key) => [key, false])
  );

  if (matchingLocale.value) {
    languageActiveMap[matchingLocale.value] = true;
  } else if (
    $resolvedLocale.value &&
    languageActiveMap[$resolvedLocale.value] != null
  ) {
    languageActiveMap[$resolvedLocale.value] = true;
  } else if (languageActiveMap['en-US'] != null) {
    // general fallback to en-US if available
    languageActiveMap['en-US'] = true;
  } else {
    // otherwise set the first locale active
    const firstLocale = Object.keys(languageActiveMap)[0];
    if (firstLocale) {
      languageActiveMap[firstLocale] = true;
    }
  }

  return languageActiveMap;
});

const metaListItems = computed((): MetaItem[] => {
  const items: MetaItem[] = [];
  if (props.download?.dateYear) {
    items.push({
      text: props.download?.dateYear,
      iconName: 'calendar'
    });
  }

  if (props.download?.fileExtension && props.download.readableFilesize) {
    items.push({
      text:
        props.download?.fileExtension + ', ' + props.download.readableFilesize,
      iconName: 'file'
    });
  }

  if (props.download?.allowedLanguages) {
    items.push({
      iconName: 'globe',
      text: useDocumentLanguage(props.download.allowedLanguages)
    });
  }

  if (props.download?.documentNumber) {
    items.push({
      text: props.download?.documentNumber,
      iconName: 'hashtag'
    });
  }

  return items;
});

function handleOnShareClick() {
  if (props.download) {
    $analytics?.pushToDataLayer({
      event: 'share',
      ecommerce: {
        method: 'Document Center',
        content_type: props.download.downloadCategory,
        item_id: `${props.download.id} | ${props.download.name}`
      }
    });
  }
}

function handleFormSuccess() {
  downloadLocked.value = false;
  if (downloadUrl.value) {
    forceDownload(downloadUrl.value);
    isDownloaded.value = true;
  }
}

function handleBackToOverview() {
  isDownloaded.value = false;
}

watch(
  () => props.download?.formRequired,
  () => {
    if (props.download) {
      downloadLocked.value = props.download.formRequired ?? false;
    }
  }
);

watch(
  () => props.open,
  () => {
    activeModal.value = props.open;

    if (!props.open) {
      // reset download state on close
      isDownloaded.value = false;
    }
  }
);
</script>

<style module>
.downloadName {
  hyphens: auto;
}

.tabsComponent :global(.tab-list__item-title) {
  /*
   * This should be moved to the TabPanel component.
   * @see https://gcp.baslerweb.com/jira/browse/DBP-1325
   */
  white-space: nowrap;
}
</style>
