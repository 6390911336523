import type { ContentfulLocaleCode } from '~/lib/ContentfulService/model';
import { useLanguageLabel } from '~/stores/downloadStore/useFilterValueLabel';

export function useDocumentLanguage(
  languageList?: ReadonlyArray<ContentfulLocaleCode>
) {
  if (!languageList) {
    return '';
  }

  if (languageList.length > 1) {
    const { $resolvedLocale } = useNuxtApp();

    const preferredLanguage =
      languageList.find((language) => language === $resolvedLocale.value) ??
      'en-US';

    return `${useLanguageLabel(preferredLanguage)} + ${
      languageList.length - 1
    }`;
  }

  return useLanguageLabel(languageList[0]);
}
